<template>
  <div>
    <!--选择日期-->
    <div style="margin-left: 5%;display: flex">
      <el-date-picker
          v-model="date_food"
          type="date"
          placeholder="选择日期"
          format="YYYY-MM-DD"
          @change="changeDate"
          style="margin-right: 50px"
          size="small"
      >
      </el-date-picker>
      <el-radio-group v-model="lunch_supper" class="ml-4" @change="changRadio">
        <el-radio label="0" size="large">早餐</el-radio>
        <el-radio label="1" size="large">午餐</el-radio>
        <el-radio label="2" size="large">晚餐</el-radio>
        <el-radio label="3" size="large">小菜</el-radio>
      </el-radio-group>
    </div>
  <!-- 制作食谱 -->
    <div>
    <!--  制作-->
      <div style="width: 90%;margin: auto">
        <div v-for="(item, index) in food_types" :key="index" style="margin-top: 2%;display: flex">
          <el-button type="primary" size="mini" style="margin-right: 2%" @click="getDialog(item.typeId)">{{ item.typeName }}</el-button>
          <div v-if="item.typeId === 1" v-for="(item, index) in main_foods" :key="index" style="margin-right: 2%">
            <el-tag>{{ item }}</el-tag>
          </div>
          <div v-if="item.typeId === 2" v-for="(item, index) in main_vegetables" :key="index" style="margin-right: 2%">
            <el-tag>{{ item }}</el-tag>
          </div>
          <div v-if="item.typeId === 3" v-for="(item, index) in fruits" :key="index" style="margin-right: 2%">
            <el-tag>{{ item }}</el-tag>
          </div>
          <div v-if="item.typeId === 4" v-for="(item, index) in hot_water" :key="index" style="margin-right: 2%">
            <el-tag>{{ item }}</el-tag>
          </div>
          <div v-if="item.typeId === 5" v-for="(item, index) in congee_foods" :key="index" style="margin-right: 2%">
            <el-tag>{{ item }}</el-tag>
          </div>
          <div v-if="item.typeId === 6" v-for="(item, index) in nonstaple_foods" :key="index" style="margin-right: 2%">
            <el-tag>{{ item }}</el-tag>
          </div>
          <div v-if="item.typeId === 7" v-for="(item, index) in little_foods" :key="index" style="margin-right: 2%">
            <el-tag>{{ item }}</el-tag>
          </div>
        </div>
      </div>
    </div>
  <!--  主食弹窗-->
    <div>
      <el-dialog
          v-model="dialogGetMainFood"
          title="添加主食"
          width="40%"
          :before-close="handleCloseMainFood">
        <div style="display: flex;flex-wrap: wrap">
          <div v-for="(item, index) in get_main_foods" :key="index" style="margin-right: 2%;margin-top: 1%">
            <el-button type="warning" @click="save_food(1, item.foodName)">{{ item.foodName }}</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 5%;flex-wrap: wrap;">
          <el-tag style="margin-right: 2%">已选择:</el-tag>
          <div v-for="(item, index) in main_foods" :key="index" style="margin-right: 1%">
            <el-tag @click="remove_food(1, index)">{{ item }}</el-tag>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  主菜弹窗-->
    <div>
      <el-dialog
          v-model="dialogGetMainVegetable"
          title="添加主菜"
          width="40%"
          :before-close="handleCloseMainFood">
        <div style="display: flex;flex-wrap: wrap;margin-top: 1%">
          <div v-for="(item, index) in get_main_vegetables" :key="index" style="margin-right: 2%;margin-top: 1%">
            <el-button type="warning" @click="save_food(2, item.foodName)">{{ item.foodName }}</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 5%;flex-wrap: wrap">
          <el-tag style="margin-right: 2%">已选择:</el-tag>
          <div v-for="(item, index) in main_vegetables" :key="index" style="margin-right: 1%">
            <el-tag @click="remove_food(2, index)">{{ item }}</el-tag>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  时蔬弹窗-->
    <div>
      <el-dialog
          v-model="dialogGetFruit"
          title="添加时蔬"
          width="40%"
          :before-close="handleCloseMainFood">
        <div style="display: flex;flex-wrap: wrap;margin-top: 1%">
          <div v-for="(item, index) in get_fruits" :key="index" style="margin-right: 2%;margin-top: 1%">
            <el-button type="warning" @click="save_food(3, item.foodName)">{{ item.foodName }}</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 5%;flex-wrap: wrap">
          <el-tag style="margin-right: 2%">已选择:</el-tag>
          <div v-for="(item, index) in fruits" :key="index" style="margin-right: 1%">
            <el-tag @click="remove_food(3, index)">{{ item }}</el-tag>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  汤弹窗-->
    <div>
      <el-dialog
          v-model="dialogGetHotWater"
          title="添加汤食"
          width="40%"
          :before-close="handleCloseMainFood">
        <div style="display: flex;flex-wrap: wrap;margin-top: 1%">
          <div v-for="(item, index) in get_hot_water" :key="index" style="margin-right: 2%;margin-top: 1%">
            <el-button type="warning" @click="save_food(4, item.foodName)">{{ item.foodName }}</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 5%;flex-wrap: wrap">
          <el-tag style="margin-right: 2%">已选择:</el-tag>
          <div v-for="(item, index) in hot_water" :key="index" style="margin-right: 1%">
            <el-tag @click="remove_food(4, index)">{{ item }}</el-tag>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  粥品弹窗-->
    <div>
      <el-dialog
          v-model="dialogGetCongeeFood"
          title="添加粥品"
          width="40%"
          :before-close="handleCloseMainFood">
        <div style="display: flex;flex-wrap: wrap;margin-top: 1%">
          <div v-for="(item, index) in get_congee_foods" :key="index" style="margin-right: 2%;margin-top: 1%">
            <el-button type="warning" @click="save_food(5, item.foodName)">{{ item.foodName }}</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 5%;flex-wrap: wrap">
          <el-tag style="margin-right: 2%">已选择:</el-tag>
          <div v-for="(item, index) in congee_foods" :key="index" style="margin-right: 1%">
            <el-tag @click="remove_food(5, index)">{{ item }}</el-tag>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  副食弹窗-->
    <div>
      <el-dialog
          v-model="dialogGetNonstapleFood"
          title="添加副食"
          width="40%"
          :before-close="handleCloseMainFood">
        <div style="display: flex;flex-wrap: wrap;margin-top: 1%">
          <div v-for="(item, index) in get_nonstaple_foods" :key="index" style="margin-right: 2%;margin-top: 1%">
            <el-button type="warning" @click="save_food(6, item.foodName)">{{ item.foodName }}</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 5%;flex-wrap: wrap">
          <el-tag style="margin-right: 2%">已选择:</el-tag>
          <div v-for="(item, index) in nonstaple_foods" :key="index" style="margin-right: 1%">
            <el-tag @click="remove_food(6, index)">{{ item }}</el-tag>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  小菜弹窗-->
    <div>
      <el-dialog
          v-model="dialogGetLittleFood"
          title="添加小菜"
          width="40%"
          :before-close="handleCloseMainFood">
        <div style="display: flex;flex-wrap: wrap;margin-top: 1%">
          <div v-for="(item, index) in get_little_foods" :key="index" style="margin-right: 2%;margin-top: 1%">
            <el-button type="warning" @click="save_food(7, item.foodName)">{{ item.foodName }}</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 5%;flex-wrap: wrap">
          <el-tag style="margin-right: 2%">已选择:</el-tag>
          <div v-for="(item, index) in little_foods" :key="index" style="margin-right: 1%">
            <el-tag @click="remove_food(7, index)">{{ item }}</el-tag>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  <!--  提交、修改-->
    <div style="float: right;margin-right: 50%;margin-top: 5%">
      <el-button type="primary" @click="save_day_food">提交</el-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {setNowDate} from "@/utils/time";
import {ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "day_food",
  computed: {
    ...mapGetters(['GET_MENU']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_DEPART']),
  },
  data(){
    return{
      date_food: '',
      editFlag: true,
      dialogGetMainFood: false,
      main_foods: [],
      get_main_foods: [],
      food_types: [],
      lunch_supper: '0',
      get_main_vegetables: [],
      dialogGetMainVegetable: false,
      get_fruits: [],
      dialogGetFruit: false,
      get_hot_water: '',
      dialogGetHotWater: false,
      hot_water: [],
      fruits: [],
      main_vegetables: [],
      nonstaple_foods: [],
      congee_foods: [],
      get_nonstaple_foods: [],
      get_congee_foods: [],
      dialogGetNonstapleFood: false,
      dialogGetCongeeFood: false,
      dialogGetLittleFood: false,
      little_foods: [],
      get_little_foods: []
    }
  },
  created() {

    this.date_food = new Date();
    this.getDayFood();
  },
  methods:{
    getDayFood(){
      this.axios.post('/imdFoodType/getAllType', (response) => {
        this.food_types = response.obj;
      },{
        food_type: this.lunch_supper
      });
      this.main_foods = [];
      this.main_vegetables = [];
      this.fruits = [];
      this.hot_water = [];
      this.congee_foods = [];
      this.little_foods = [];
      this.nonstaple_foods = [];
      this.axios.post('/imdDayFood/getDayFood', (response) => {
        for (let i = 0; i < response.obj.length; i++){
          if (response.obj[i].foodType === '主食'){
            let foods = response.obj[i].foodName.split(",");
            for (let y = 0; y < foods.length; y++){
              this.main_foods.push(foods[y]);
            }
          }
          if (response.obj[i].foodType === '主菜'){
            let foods = response.obj[i].foodName.split(",");
            for (let y = 0; y < foods.length; y++){
              this.main_vegetables.push(foods[y]);
            }
          }
          if (response.obj[i].foodType === '时蔬'){
            let foods = response.obj[i].foodName.split(",");
            for (let y = 0; y < foods.length; y++){
              this.fruits.push(foods[y]);
            }
          }
          if (response.obj[i].foodType === '汤品'){
            let foods = response.obj[i].foodName.split(",");
            for (let y = 0; y < foods.length; y++){
              this.hot_water.push(foods[y]);
            }

          }
          if (response.obj[i].foodType === '副食'){
            let foods = response.obj[i].foodName.split(",");
            for (let y = 0; y < foods.length; y++){
              this.nonstaple_foods.push(foods[y]);
            }
          }
          if (response.obj[i].foodType === '粥品'){
            let foods = response.obj[i].foodName.split(",");
            for (let y = 0; y < foods.length; y++){
              this.congee_foods.push(foods[y]);
            }
          }
          if (response.obj[i].foodType === '小菜'){
            let foods = response.obj[i].foodName.split(",");
            for (let y = 0; y < foods.length; y++){
              this.little_foods.push(foods[y]);
            }
          }
        }
      },{
        food_time: setNowDate(this.date_food),
        lunch_supper: this.lunch_supper
      })
    },
    changeDate(){
      this.getDayFood();
    },
    changRadio(){
      this.getDayFood();
    },
    // 根据 id 打开弹窗
    getDialog(item){
      if (new Date(setNowDate(new Date())).getTime() <= new Date(setNowDate(this.date_food)).getTime()){
        this.axios.post('/imdFoodKinds/getFoodKinds', (response) => {
          if (item === 1){
            this.get_main_foods = response.obj;
          }
          if (item === 2){
            this.get_main_vegetables = response.obj;
          }
          if (item === 3){
            this.get_fruits = response.obj;
          }
          if (item === 4){
            this.get_hot_water = response.obj;
          }
          if (item === 5){
            this.get_congee_foods = response.obj;
          }
          if (item === 6){
            this.get_nonstaple_foods = response.obj;
          }
          if (item === 7){
            this.get_little_foods = response.obj;
          }
        },{
          type: item
        })
        if (item === 1){
          this.dialogGetMainFood = true;
        }
        if (item === 2){
          this.dialogGetMainVegetable = true;
        }
        if (item === 3){
          this.dialogGetFruit = true;
        }
        if (item === 4){
          this.dialogGetHotWater = true;
        }
        if (item === 5){
          this.dialogGetCongeeFood = true;
        }
        if (item === 6){
          this.dialogGetNonstapleFood = true;
        }
        if (item === 7){
          this.dialogGetLittleFood = true;
        }
      }else{
        ElMessage.error("不可修改今日之前的菜谱!")
      }

    },
    // 关闭主食弹窗
    handleCloseMainFood(){
      this.dialogGetMainFood = false;
      this.dialogGetMainVegetable = false;
      this.dialogGetFruit = false;
      this.dialogGetHotWater = false;
      this.dialogGetCongeeFood = false;
      this.dialogGetNonstapleFood = false;
      this.dialogGetLittleFood = false;
    },
    // 添加主食
    save_food(item1, item2){
      if (item1 === 1){
        this.main_foods.push(item2);
      }
      if (item1 === 2){
        this.main_vegetables.push(item2);
      }
      if (item1 === 3){
        this.fruits.push(item2);
      }
      if (item1 === 4){
        this.hot_water.push(item2);
      }
      if (item1 === 5){
        this.congee_foods.push(item2);
      }
      if (item1 === 6){
        this.nonstaple_foods.push(item2);
      }
      if (item1 === 7){
        this.little_foods.push(item2);
      }

    },
    // 删除已添加的主食
    remove_food(item1, item2){
      if (item1 === 1){
        this.main_foods.splice(item2, 1);
      }
      if (item1 === 2){
        this.main_vegetables.splice(item2, 1);
      }
      if (item1 === 3){
        this.fruits.splice(item2, 1);
      }
      if (item1 === 4){
        this.hot_water.splice(item2, 1);
      }
      if (item1 === 5){
        this.congee_foods.splice(item2, 1);
      }
      if (item1 === 6){
        this.nonstaple_foods.splice(item2, 1);
      }
      if (item1 === 7){
        this.little_foods.splice(item2, 1);
      }

    },
    add_food_yes(){
      this.dialogGetMainFood = false;
      this.dialogGetMainVegetable = false;
      this.dialogGetFruit = false;
      this.dialogGetHotWater = false;
      this.dialogGetCongeeFood = false;
      this.dialogGetNonstapleFood = false;
      this.dialogGetLittleFood = false;
    },
    // 保存食谱
    save_day_food(){
      let ls;
      if (this.lunch_supper === '0'){
        ls = '早餐';
      }
      if (this.lunch_supper === '1'){
        ls = '午餐';
      }
      if (this.lunch_supper === '2'){
        ls = '晚餐';
      }
      if (this.lunch_supper === '3'){
        ls = '小料';
      }
      ElMessageBox.confirm(
          '是否提交' + setNowDate(this.date_food) + ls + '的食谱?',
          '提示',
          {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.axios.post('/imdDayFood/saveDayFood', (response) => {
              if (response.obj){
                ElMessage({
                  message: '制作食谱成功!',
                  type: 'success'
                });
                this.reload();
              }
            },{
              mainFood: this.main_foods,
              mainVegatable: this.main_vegetables,
              fruit: this.fruits,
              hotWater: this.hot_water,
              up_user: this.GET_USER,
              food_time: setNowDate(this.date_food),
              flag: '0',
              lunch_supper: this.lunch_supper,
              littleFood: this.little_foods,
              congeeFoods: this.congee_foods,
              nonstapleFoods: this.nonstaple_foods
            })
          })
          .catch(() => {

          })

    }

  }
}
</script>

<style scoped>

</style>