<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">食谱管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="菜品管理" name="a"><Food_kinds></Food_kinds></el-tab-pane>
        <el-tab-pane label="食谱管理" name="b"><Day_food></Day_food></el-tab-pane>
	      <el-tab-pane label="上传食谱" name="c"><Up_cook_book></Up_cook_book></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";
import Food_kinds from "@/views/imd/food/cookbook/food_kinds";
import Day_food from "@/views/imd/food/cookbook/day_food";
import Up_cook_book from "@/views/imd/food/cookbook/up_cook_book.vue";

export default {
  name: "cookbook",
  components: {Day_food, Food_kinds, Header},
  data(){
    return{
      activeName: 'a',
	    a: true,
	    b: false,
	    c: false
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
    if (sessionStorage.getItem('cook_book')){
      this.activeName = sessionStorage.getItem('cook_book');
	    if (this.activeName === 'a'){
		    this.a = true
		    this.b = false
		    this.c = false
	    }
	    if (this.activeName === 'b'){
		    this.b = true
		    this.a = false
		    this.c = false
	    }
	    if (this.activeName === 'b'){
		    this.c = true
		    this.b = false
		    this.a = false
	    }
    }
  },
  methods:{
    handleClick(tab, event){
      //console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('cook_book', tab.props.name)
	    if (tab.props.name === 'a'){
		    this.a = true
		    this.b = false
		    this.c = false
	    }
	    if (tab.props.name === 'b'){
		    this.b = true
		    this.a = false
		    this.c = false
	    }
	    if (tab.props.name === 'b'){
		    this.c = true
		    this.b = false
		    this.a = false
	    }
    },
    reall(){
      sessionStorage.removeItem('cook_book');
    },
  }

}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}

#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>