<template>
  <div>
  <!--  菜品-->
    <div style="margin-left: 5%;display: flex">
      <div>
        <el-tag effect="dark">菜品分类</el-tag>
        <el-select v-model="food_type" clearable placeholder="请选择菜品分类" size="small" @change="get_type">
          <el-option
              v-for="item in food_types"
              :key="item.typeId"
              :label="item.typeName"
              :value="item.typeId"
          />
        </el-select>
      </div>
      <div style="display: flex;margin-left: 5%">
        <el-tag effect="dark">菜品名称</el-tag>
        <el-input v-model="food_name" placeholder="请输入菜品名称" clearable style="width: 90%" size="small" @input="get_type"/>
      </div>
      <div style="margin-left: 2%">
        <el-radio-group v-model="remove_flag" class="ml-4" @change="get_type">
          <el-radio label="0" size="large">未删除</el-radio>
          <el-radio label="1" size="large">已删除</el-radio>
        </el-radio-group>
      </div>
      <div style="margin-left: 10%">
        <el-button type="primary" @click="save_kind" size="small">新增菜品</el-button>
      </div>
    </div>

  <!--  菜品列表-->
    <div style="width: 98%;margin-left: auto;margin-top: 1%">
      <el-table :data="food_kinds" style="width: 30%;">
        <el-table-column prop="bdst" label="类别" width="180" >
          <template #default="scope">
            <span v-if="scope.row.foodType === 1">主食</span>
            <span v-if="scope.row.foodType === 2">主菜</span>
            <span v-if="scope.row.foodType === 3">时蔬</span>
            <span v-if="scope.row.foodType === 4">汤品</span>
            <span v-if="scope.row.foodType === 5">粥品</span>
            <span v-if="scope.row.foodType === 6">副食</span>
            <span v-if="scope.row.foodType === 7">小菜</span>
          </template>
        </el-table-column>
        <el-table-column prop="foodName" label="菜品名称" width="280" />
        <el-table-column label="操作" >
          <template #default="scope">
            <el-button v-if="remove_flag === '0'" type="danger" @click="remove_food(scope.row)" size="mini"> 删除 </el-button>
            <el-button v-if="remove_flag === '1'" type="warning" @click="remove_food(scope.row)" size="mini"> 恢复 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  <!--  分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
  <!--  新增菜品-->
    <div>
      <el-dialog
          v-model="dialogAddFood"
          title="添加主食"
          width="40%"
          :before-close="handleCloseAddFood">
        <div>
          <el-tag effect="dark">菜品分类</el-tag>
          <el-select v-model="add_food_type" clearable placeholder="请选择菜品分类" size="small">
            <el-option
                v-for="item in add_food_types"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId"
            />
          </el-select>
        </div>
        <div style="display: flex;margin-top: 5%">
          <el-tag effect="dark">菜品名称</el-tag>
          <el-input v-model="add_food_name" placeholder="请输入菜品名称" clearable style="width: 22%" size="small"/>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_food_yes()">确认添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { ElMessage } from 'element-plus';

export default {
  name: "food_kinds",
  inject: ['reload'],
  computed: {
    ...mapGetters(['GET_MENU']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_DEPART']),
  },
  data(){
    return{
      food_type: '',
      food_types: [],
      food_name: '',
      food_kinds: [],
      totals: 0,
      pagenum: 1,
      remove_flag: '0',
      dialogAddFood: false,
      add_food_type: '',
      add_food_types: [],
      add_food_name: ''
    }
  },
  created() {
    this.remove_flag = sessionStorage.getItem('food_kind_remove_flag');
    this.axios.post('/imdFoodType/newGetAllType', (response) => {
      this.food_types = response.obj;
      this.add_food_types = response.obj;
    });
    this.axios.post('/imdFoodKinds/getKindFoodsForPc', (response) => {
      this.food_kinds = response.obj.records;
      this.totals = response.obj.pages * 10;
    },{
      pageCount: 1,
      remove_flag: this.remove_flag,
      food_type: this.food_type,
      food_name: this.food_name
    });
  },
  methods:{
    get_type(){
      sessionStorage.setItem('food_kind_remove_flag', this.remove_flag);
      this.axios.post('/imdFoodKinds/getKindFoodsForPc', (response) => {
        this.food_kinds = response.obj.records;
        this.totals = response.obj.pages * 10;
      },{
        pageCount: 1,
        remove_flag: this.remove_flag,
        food_type: this.food_type,
        food_name: this.food_name
      });
    },
    add_food_yes(){
      this.axios.post('/imdFoodKinds/saveFoodKind', (response) => {
        if (response.obj){
          ElMessage({
            message: '添加成功!',
            type: 'success'
          });
          this.add_food_type = '';
          this.add_food_name = '';
        }else {
          ElMessage.error('添加失败，请联系科信部!')
        }
      },{
        type: this.add_food_type,
        food_name: this.add_food_name
      })
    },
    save_kind(){
      this.dialogAddFood = true;
    },
    handleCloseAddFood(){
      this.add_food_type = '';
      this.add_food_name = '';
      this.dialogAddFood = false;
      this.reload();
    },
    remove_food(item){
      this.axios.post('/imdFoodKinds/saveRemoveFood', (response) => {
        if (response.obj){
          ElMessage({
            message: '操作成功!',
            type: 'success'
          });
          this.reload();
        }
      },{
        remove_flag: this.remove_flag,
        id: item.id
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.post('/imdFoodKinds/getKindFoodsForPc', (response) => {
        this.food_kinds = response.obj.records;
        this.totals = response.obj.pages * 10;
      },{
        pageCount: this.pagenum,
        remove_flag: this.remove_flag,
        food_type: this.food_type,
        food_name: this.food_name
      });
    }
  }
}
</script>

<style scoped>

</style>